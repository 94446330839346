import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    paddingVertical: 4,
    paddingLeft: 4,
  },

  text: {
    textAlign: 'right',
    backgroundColor: 'transparent',
  },
});
